.App {
  text-align: center;
}

body {
  margin: 0;
  background: #000;
  font-family: 'Mona-Sans', sans-serif;
  font-weight: 800;
  
                                                                    font-family: 'Mona Sans', sans-serif;
}

code {
  font-family: 'Mona-Sans', sans-serif;
  font-family: 'Mona Sans', sans-serif;
  font-weight: 800;
}
.Background {
  background: rgb(5,49,126);
  background: radial-gradient(circle, rgba(5,49,126,1) 0%, rgba(1,1,1,1) 100%); 
}
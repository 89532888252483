h1,h2,h3,h4,h5,h6 {
  color: #000000;
}
p {
  color: #000000;
} 

.card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  background-color: #888080;
}

.card-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.card-subtitle {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-value {
  font-size: 16px;
  margin-bottom: 20px;
}

.card-approved {
  color: green;
  margin-bottom: 20px;
}

.card-text {
  margin-bottom: 10px;
}

.card-link {
  color: blue;
  text-decoration: none;
}

.percentage-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.percentage-buttons button {
  width: 20%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.percentage-buttons button:hover {
  background-color: #0056b3;
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  flex-direction: column;
  width: 450px;
  border: 1px solid #02112c;
  border-radius: 35px;
  box-shadow: 0 0 10px 5px #021027;
  padding: 25px 25px 15px;
  background: #042a6b;
  position: fixed;
  background-blend-mode: multiply;
  top: 33%;
}

.card h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.card__balance {
  font-size: 18px;
  margin-bottom: 15px;
  color: black;
}

.card__input-section {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.card__input-section input {
  width: 22%;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  color: black;
  background: transparent;
}

.percentage-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
padding-top: 10px;
}

.percentage-btn {
  padding: 10px;
  font-size: 14px;
  color: #000000;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  width: 22%;
}

.percentage-btn:hover {
  opacity: 0.8;
}

.approve-btn,
.deposit-btn {
  padding: 10px 20px;
  font-size: 16px;
  color: #000000;
  background-color: #007bff;
  border: none;
  box-shadow: 0 0 5px 2px #eee;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 100px;
width: 100%;
}

.approve-btn:hover,
.deposit-btn:hover {
  opacity: 0.8;
}

.close-btn {
  padding: 10px;
  color: white;
  font-weight: 900;
  background: transparent;
  border: 1px solid black;
}
.wrapper-closebtn {
  width: 100%;
display: grid;
justify-content: end;
bottom: 30px;
left: 25px;
}
.modal-container {
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 15px;
  display: block;
}